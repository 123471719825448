import "assets/scss/dashboard.scss";

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import FormControlListSelector from "pages/charts/EffUnit/FormControlListSelector.jsx";
import { getDataApi } from "services/appApi";

function EffUnitChartPage() {
  const [processes, setProcesses] = useState([]);
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areMultipleChartAreasShown, setAreMultipleChartAreasShown] = useState(false);
  const [loadingErrors, setLoadingErrors] = useState([]);

  const toggleSecondChartArea = () => {
    setAreMultipleChartAreasShown(!areMultipleChartAreasShown);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let res = await getDataApi("/api/chart/getAllProcessesMachines");
        if (res) {
          let successFlag = res.data[0];
          if (successFlag === "Y") {
            setProcesses(res.data[1]);
            setMachines(res.data[2]);
            setIsLoading(false);
          } else {
            setLoadingErrors(res.data[2]);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoadingErrors(err);
      }
    };
    fetchData();
  }, []);


  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Machine Efficiency By Units Chart
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }


  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i
                className={
                  areMultipleChartAreasShown
                    ? "fa-duotone fa-fw fa-square-minus"
                    : "fa-duotone fa-fw fa-square-plus"
                }
              >
            
            <span className="normal-font"> Machine Efficiency By Units Chart </span>
            <button
              type="button"
              id="btnSecondChart"
              className={
                areMultipleChartAreasShown
                  ? "btn btn-danger mt-2 mt-md-0 ms-md-3"
                  : "btn btn-success mt-2 mt-md-0 ms-md-3"
              }
              onClick={toggleSecondChartArea}
            >
              
              {areMultipleChartAreasShown ? "Remove " : "Add "} 2<sup>nd</sup> Chart
            </button> </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }


  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>



        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
              Machine Efficiency By Units Chart are Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>
                      error
                    </li>
                  ))}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }


  const getFormControls = (chartId) => {
    return (
      <React.Fragment>
        <div className="col">
          <div className="row justify-content-center">
            <div className="col ps-2 pe-2">
              {!isLoading && (
                <div id="chart-container-1" className="chart-container">
                  <FormControlListSelector
                    processes={processes}
                    machines={machines}
                    chartId={chartId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="dashboard">
      <div className="dashboardContainer">
        <div className="charts">
          {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
          <div className="row">
            {loadingErrors.length > 0 ? getErrrorMsgContents() : getFormControls("EffUnitChart1")}

            {loadingErrors.length <= 0 && areMultipleChartAreasShown
              ? getFormControls("EffUnitChart2") : ""}

          </div>
        </div>
      </div>
    </div>
  );
}

export default EffUnitChartPage;
