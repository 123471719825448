import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import './DxpTable.css';
import { getDataApi, saveApi } from "../../../services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const CommunicationsPG = () => {
  let emptyRow = {
    comm_id: null,
    comm_name: "",
    node_name: "",
    prog_id: "",
    access_path: "",
    update_rate: 0,
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch rows data on component mount
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("api/setup/dxpCommunications/selRows ");
        console.log(response.data); // Check the structure of the fetched data
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            setRows(response.data[1]); // Ensure response.data[1] has the expected structure
            setIsLoading(false);
          } else {
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
        } else {
          setRows([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching communications:", error);
      }
    };

    fetchRows();
  }, []);

  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const hideDeleteRowDialog = () => {
    setDeleteRowDialog(false);
  };

  const saveRow = async () => {
    setSubmitted(true);

    if (row.comm_name.trim()) {
      let _rows = [...rows];
      let _row = { ...row };

      try {
        if (row.comm_id) {
          /*** Update Row */
          const response = await saveApi(row, "/api/setup/communications/updRow");
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            const index = findIndexById(row.comm_id);
            _rows[index] = _row;
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Communication Updated",
              life: 3000,
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Communication Not Updated",
              life: 3000,
            });
          }
        } else {
          /*** Insert Row */
          const response = await saveApi(row, "/api/setup/dxpCommunications/insRows");
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            _row.comm_id = response.data[1];
            _rows.push(_row);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Communication Created",
              life: 3000,
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Communication Not Created",
              life: 3000,
            });
          }
        }

        setRows(_rows);
        setRowDialog(false);
        setRow(emptyRow);
      } catch (error) {
        console.error("Error saving communication:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Communication Save Failed",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        life: 3000,
      });
    }
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const confirmDeleteRow = (row) => {
    setRow(row);
    setDeleteRowDialog(true);
  };

  const deleteRow = async () => {
    try {
      const response = await saveApi(row, "/api/setup/dxpCommunications/delRow");
      let successFlag = response.data[0];
      if (successFlag === "Y") {
        let _rows = rows.filter((val) => val.comm_id !== row.comm_id);
        setRows(_rows);
        setDeleteRowDialog(false);
        setRow(emptyRow);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Communication Deleted",
          life: 3000,
        });
      } else {
        let err_msg = response.data[1];
        toast.current.show({
          severity: "error",
          summary: err_msg,
          detail: "Communication Not Deleted",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error deleting communication:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Communication Deletion Failed",
        life: 3000,
      });
    }
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].comm_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteRowDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteRowDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteRow}
      />
    </React.Fragment>
  );

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _row = { ...row };
    _row[`${name}`] = val;

    setRow(_row);
  };
  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Communications
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> Communications </span><br/><br/>
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px; 
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div>
         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }
  

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );

  const showProduct = (row) => {
    setRow({ ...row });
    setRowDialog(true);
}

  
  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="grid crud-demo">
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
        <div className="card">
          {/* <div className="text-3xl text-800 font-bold mb-4">Communications</div> */}
          <DataTable
            ref={dt}
            value={rows}
            scrollable scrollHeight="57rem"     
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            dataKey="comm_id"
            paginator
            rows={pecaPageRows}
            rowsPerPageOptions={pecaRowsPerPageOptions}
            globalFilter={globalFilter}
            header={header}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
            responsiveLayout="scroll"
            emptyMessage="No communications found."
          >
            <Column body={readBodyTemplate} exportable={false}></Column>
            <Column field="comm_name" header="Comm Name" sortable></Column>
            <Column field="node_name" header="Node Name" sortable></Column>
            <Column field="prog_id" header="Prog ID" sortable></Column>
            <Column field="access_path" header="Access Path" sortable></Column>
            <Column field="update_rate" header="Update Rate" sortable></Column>
            
          </DataTable>
          
          <Dialog
            visible={rowDialog}
            style={{ width: "450px" }}
            header="Row Details"
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
        
              <label htmlFor="comm_name">Comm Name</label>
              <div className="box">
                        {row.comm_name}
                    </div>
             
             
              <label htmlFor="node_name">Node Name</label>
                    <div className="box">
                        {row.node_name}
                    </div>

              <label htmlFor="prog_id">Prog ID</label>
                      <div className="box">
                        {row.prog_id}
                      </div>
              
              <label htmlFor="access_path">Access Path</label>
                  <div className="box">
                        {row.access_path}
                    </div>
              
              <label htmlFor="update_rate">Update Rate</label>
              <div className="box">
                        {row.update_rate}
                    </div>
              
          </Dialog>

          <Dialog
            visible={deleteRowDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteRowDialogFooter}
            onHide={hideDeleteRowDialog}
          >
            <div className="flex flex-column align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle p-overlay-badge"
                style={{ fontSize: "2rem", marginBottom: "1rem" }}
              ></i>
              <span>Are you sure you want to delete this communication?</span>
            </div>
          </Dialog>
          <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
        </div>
          )}
      </div>
    </div>
  );
};

export default CommunicationsPG;
