export const baseUrl =  'http://89.116.187.234:10053'; /** Radix Backend Oracle Server */
//export const baseUrl =  'http://89.116.187.234:31553'; /** Radix Backend mySQL Server */

//export const baseUrl = 'http://localhost:3001';
// export const baseUrl =  'http://10.132.200.77:31553'; /** PECA Backend Oracle test server */
// export const baseUrl =  'http://localhost:31553';
/*
axios.post('http://89.116.187.234:3002/' + title, data).then((result) => {
      if (result) {
       //result
      } else {
        // no result
      }
    }); */

    export const pecaDbName = 'TEST';
    // export const pecaDbName = 'DYBQR53';
    // export const pecaDbName = 'DYBQR93';
    // export const pecaDbName = 'DYBQR03';