import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect,useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import './PollDataFiles.css';
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollDataFiles  = () => {
  let emptyRow = {
    file_id: null,
    file_name: "",
    first_row_data: "",
    second_row_data: "",
    ora_machine_id: "",
    machine_name: "",
    dxp_file_prefix: "",
    process_name: "",
    production_line_number: "",
    ora_processed_flag: "",
    ora_error_message: "",
    num_of_data_rows: "",
    remote_ip_address: "",
    orig_ftpin_date: "",
    last_ftpin_date: "",
    remote_file_size_bytes: "",
    local_file_size_bytes: "",
    local_file_delete_date: "",
    remote_user_name: "",
    remote_dir_name: "",
    creation_date: "",
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [fileName, setFileName]=useState("");
  const [machineName, setMachineName]=useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const p_param = {
    p_file_id:null,
    p_file_name:null,
    p_process_id:null,
    p_line_number:null,
    p_machine_id:null,
    p_start_datetime:null,
    p_end_datetime:null,
    p_machine_name_like:null,
  };
  
  useEffect(() => {
    const fetchData = async () => {
        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);
      }

    fetchData();
  }, []);

 
    const handleLoadData = async () => {
      const p_param = {
        p_start_datetime: startDate,
        p_end_datetime: endDate,
        p_file_name: fileName,
        p_machine_name_like: machineName,
     };
     console.log(p_param);
      try {
        setIsLoading(true);
        const response = await loadApi(p_param,"/api/txn/getPollDataFiles");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(true);
            const errorInfo = response.data[2];
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

const exportCSV = () => {

  const csvRows = [];
  const headers = ['File Id','Date', 'File Name', 'Machine Name', 'File Prefix',
                    'Process', 'Ln#', 'OraProcessed?', 'Ora Errors', '#DataRows', 
                    'FTP IP', 'First ftp date', 'Last ftp date', 'Remote File Size(B)','Local File Size(B)',
                    'Local File Deleted on','FTP User','Remote Dir'
  ];
  csvRows.push(headers.join(','));

  for (const row of rows) {
    const values = [
      `"${row.file_id}"`,
      `"${formatCreationDate(row)}"`,
      `"${row.file_name}"`,
      `"${row.machine_name}"`,
      `"${row.dxp_file_prefix}"`,
      `"${row.process_name}"`,
      `"${row.production_line_number}"`,
      `"${row.ora_processed_flag}"`,
      `"${row.ora_error_message}"`,
      `"${row.num_of_data_rows}"`,
      `"${row.remote_ip_address}"`,
      `"${row.orig_ftpin_date}"`,
      `"${row.last_ftpin_date}"`,
      `"${row.remote_file_size_bytes}"`,
      `"${row.local_file_size_bytes}"`,
      `"${row.local_file_delete_date}"`,
      `"${row.remote_user_name}"`,
      `"${row.remote_dir_name}"`
    ];
    csvRows.push(values.join(','));
  }

  const csvContent = csvRows.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'DXP_Poll_Data_Files.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              DXP Poll Data Files
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
const getLoadedHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <i>
           
          
          <span className="normal-font"> DXP Poll Data Files </span><br/><br/>
          {/* <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div> */}
       
          </i>
        </h2>
      </div>
    </React.Fragment>
  );
}
const getErrrorMsgContents = () => {
  return (
    <React.Fragment>
      <div class="row mt-5 justify-content-md-center">
        <div class="col-3">
          <div class="card border-secondary">
            <div class="card-header bg-dark text-white">
            Dxp Poll Data Interface Errors Summary table is Unavailable
            </div>
            <div class="card-body card-body-error rounded-bottom">
              <ul>
                {loadingErrors.map((error) => (
                  <li>
                    error
                  </li>
                ))}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
const getSearchControls = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">Search</label>
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="input-outline" />
           </div>
  </React.Fragment>
);

const getFileNameControl = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">File Name</label>
          <InputText 
          type="text"
          id = "FileName"
          name="FileName" 
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter file name"
          className="input-outline" />
           </div>
  </React.Fragment>
);
const getMachineNameControl = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">Machine Name</label>
          <InputText 
          type="text"
          id = "MacName"
          name="MacName" 
          onChange={(e) => setMachineName(e.target.value)}
          placeholder="Enter machine name"
          className="input-outline" />
           </div>
  </React.Fragment>
);


const getStartEndDateControls = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Start Date</label>
      <input
        type="datetime-local"
        id="StartDateTime"
        name="StartDateTime"
        value={startDate}
        onChange={handleStartDateChange}
        className="input-outline"
      />
    </div>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">End Date</label>
      <input
        type="datetime-local"
        id="EndDateTime"
        name="EndDateTime"
        value={endDate}
        onChange={handleEndDateChange}
        className="input-outline"
      />
    </div>
  </React.Fragment>
);

const handleStartDateChange = (e) => {
  setStartDate(e.target.value);
};

const handleEndDateChange = (e) => {
  setEndDate(e.target.value);
};

const getGoButtonControl = () => (
  <React.Fragment>
      <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
        <button
          type="button"
          className="btn btn-primary load-button"
          id={"chartAreaId"}
          onClick={handleLoadData}
          disabled={isLoading}
        >
          {isLoading ? (
                  <i className="pi pi-spin pi-spinner"></i>
                ) : null}
          <span>{isLoading ? "Loading" : "Go"}</span>
        </button>
      </div>
  </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
     <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const formatCreationDate = (rowData) => {
    return moment(rowData.creation_date).format('YYYY-MM-DD HH:mm:ss');
  };
  const formatDate = moment(row.creation_date).format('YYYY-MM-DD HH:mm:ss');

  return (
    <div>
      <Toast ref={toast} />
      {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()} 
          {getStartEndDateControls()} 
          {getFileNameControl()}
          {getMachineNameControl()}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">DXP Poll Data Files</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"     
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="file_id"
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
         
          <Column body={readBodyTemplate} exportable={false}></Column>
          <Column field="file_id" header="File Id" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="creation_date" header="Date" sortable body={formatCreationDate} style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="first_row_data" header="First Row Data" sortable hidden></Column>
          <Column field="second_row_data" header="Second Row Data" sortable hidden></Column>
          <Column field="ora_machine_id" header="Ora Machine Id" sortable hidden></Column>
          <Column field="machine_name" header="Machine Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="dxp_file_prefix" header="File Prefix" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="process_name" header="Process" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="production_line_number" header="Ln#" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="ora_processed_flag" header="OraProcessed?" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="ora_error_message" header="Ora Errors" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="num_of_data_rows" header="#DataRows" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="remote_ip_address" header="FTP IP" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="orig_ftpin_date" header="First ftp date" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="last_ftpin_date" header="Last ftp date" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="remote_file_size_bytes" header="Remote File Size(B)" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="local_file_size_bytes" header="Local File Size(B)" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="local_file_delete_date" header="Local File Deleted on" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="remote_user_name" header="FTP User" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="remote_dir_name" header="Remote Dir" sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
      )}
      </div>
      </div>

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="DXP Poll Data Files" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="file_id">File Id</label>
                    <div className="box">
                        {row.file_id}
                    </div>

                <label htmlFor="creation_date">File Id</label>
                    <div className="box">
                        {formatDate}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="machine_name">Machine Name</label>
                    <div className="box">
                        {row.machine_name}
                    </div>

                <label htmlFor="dxp_file_prefix">File Prefix</label>
                    <div className="box">
                        {row.dxp_file_prefix}
                    </div>

                <label htmlFor="process_name">Process</label>
                    <div className="box">
                        {row.process_name}
                    </div>

                <label htmlFor="production_line_number">Ln#</label>
                    <div className="box">
                        {row.production_line_number}
                    </div>

                <label htmlFor="ora_processed_flag">OraProcessed?</label>
                    <div className="box">
                        {row.ora_processed_flag}
                    </div>

                <label htmlFor="name_ruleora_error_message">Ora Errors</label>
                    <div className="box">
                        {row.ora_error_message}
                    </div>

                    <label htmlFor="num_of_data_rows">#DataRows</label>
                    <div className="box">
                        {row.num_of_data_rows}
                    </div>

                <label htmlFor="remote_ip_address">FTP IP</label>
                    <div className="box">
                        {row.remote_ip_address}
                    </div>

                <label htmlFor="orig_ftpin_date">First ftp date</label>
                    <div className="box">
                        {row.orig_ftpin_date}
                    </div>

                <label htmlFor="last_ftpin_date">Last ftp date</label>
                    <div className="box">
                        {row.last_ftpin_date}
                    </div>

                <label htmlFor="remote_file_size_bytes">Remote File Size(B)</label>
                    <div className="box">
                        {row.remote_file_size_bytes}
                    </div>

                <label htmlFor="local_file_size_bytes">Local File Size(B)</label>
                    <div className="box">
                        {row.local_file_size_bytes}
                    </div>

                <label htmlFor="local_file_delete_date">Local File Deleted on</label>
                    <div className="box">
                        {row.local_file_delete_date}
                    </div>

                <label htmlFor="remote_user_name">FTP User</label>
                    <div className="box">
                        {row.remote_user_name}
                    </div>

                <label htmlFor="remote_dir_name">Remote Dir</label>
                    <div className="box">
                        {row.remote_dir_name}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>

    </div>
  );
};

export default PollDataFiles;

