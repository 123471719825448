import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "assets/scss/sidemenu.scss";
import { Menubar } from "primereact/menubar";

function TopMenu() {
  const navigate = useNavigate();
  const handleNavigate = (param) => {
    navigate(`/logger?param=${param}`);
  };
  const itemRenderer = (item) => (
    <Link
      to={item.link}
      className="flex align-items-center p-menuitem-link"
      style={{ textDecoration: "none" }}
    >
      <span className={item.icon} />
      <span className="mx-0" style={{ textDecoration: "none" }}>
        {item.label}
      </span>
    </Link>
  );

  const items = [
    {
      label: "PECA PDCS",
      template: () => (
        <span className="inline-flex align-items-center gap-1 px-1 py-1 font-medium text-xl font-semibold">
          PECA<span className="text-primary">PDCS</span>
        </span>
      ),
    },
    {
      label: "Charts",
      items: [
        {
          label: "Machine",
          template: () => <span className="dropdown-heading">Machine</span>,
        },
        {
          label: "Efficiency - Operation Time",
          link: "/charts/EffOpTime",
          template: itemRenderer,
        },
        {
          label: "Efficiency - Units Produced",
          link: "/charts/EffUnit",
          template: itemRenderer,
        },
        {
          label: "Machine Stops",
          link: "/charts/MachineStops",
          template: itemRenderer,
        },
        {
          label: "CA | VA Checker Running Totals",
          link: "/charts/VAChecker",
          template: itemRenderer,
        },
        {
          label: "TPA | Inspection NG",
          link: "/charts/TPAInsNG",
          template: itemRenderer,
        },
        { separator: true },
        {
          label: "Line",
          template: () => <span className="dropdown-heading">Lines</span>,
        },
        {
          label: "Pareto | Machine Stops",
          link: "/charts/ParetoMachStops",
          template: itemRenderer,
        },
        {
          label: "Pareto | Reject Ratio",
          link: "/charts/ParetoRejectsMach",
          template: itemRenderer,
        },
        {
          label: "Wait Times",
          link: "/charts/LineWaitChart",
          template: itemRenderer,
        },
        { label: "Weight Checker", link: "#", template: itemRenderer },
      ],
    },
    {
      label: "Dashboards",
      items: [
        {
          label: "Cell Assembly",
          link: "/setups/CellAssembly/CellAssembly",
          template: itemRenderer,
        },
        {
          label: "Top Plate Assembly",
          link: "/setups/TPADashboards/TPADashboards",
          template: itemRenderer,
        },
      ],
    },
    {
      label: "Operator Entry",
      items: [
        { label: "Top Plate Assembly", link: "#", template: itemRenderer },
      ],
    },
    {
      label: "Admin Pages",
      items: [
        {
          label: "Poll Data",
          items: [
            {
              label: "Dxp Poll Data Files",
              link: "/Admin/PollData/PollDataFiles",
              template: itemRenderer,
            },
            {
              label: "DXP Poll Data Column Errors",
              link: "/Admin/PollData/PollDataFileColumns",
              template: itemRenderer,
            },
            {
              label: "Dxp Poll Data Interface Errors Summary",
              link: "/Admin/PollData/ErrorSummary",
              template: itemRenderer,
            },
            {
              label: "Dxp Poll Data Errors",
              link: "/Admin/PollData/PollDataErrors",
              template: itemRenderer,
            }
          ],

        },
        {
          label: "Transactions",
          items: [
            {
              label: "Poll Data Archives",
              link: "/Admin/Transactions/Poll_data_archives",
              template: itemRenderer,
            },
          ],
        },
        {
          label: "Setup",
          items: [
            {
              label: "Machines",
              link: "/setups/machines",
              template: itemRenderer,
            },
            {
              label: "Machine Addresses",
              link: "setups/MachineAddress",
              template: itemRenderer,
            },
          ],
        },
        {
          label: "DxpConfigs",
          items: [
            { label: 'Events', link: '/setups/DxpConfigs/Events', template: itemRenderer },
           { label: 'Communications', link: '/setups/DxpConfigs/Communications', template: itemRenderer },
           { label: 'Tags', link: '/setups/DxpConfigs/Tags', template: itemRenderer },

            {
              label: "Loggings",
              link: "/setups/DxpData/DxpDataLoggers",
              template: itemRenderer,
            },
            {
              label: "Logging Details",
              link: "/setups/DxpData/DxpDataLoggersLogDet",
              template: itemRenderer,
            },

            {
              label: "Event Details",
              link: "/setups/DxpData/DxpDataLoggersLogDetEvent",
              template: itemRenderer,
            },
            {
              label: "Event Triggers",
              link: "/setups/DxpData/DxpDataLoggersEventTrig",
              template: itemRenderer,
            },
            
            {
              label: "Import Configs",
              link: "#",
              template: itemRenderer,
            },
          ],
        },
      ],
    },
  ];

  return <Menubar model={items} />;
}

export default TopMenu;
