import React, { useEffect, useState } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowMachStopcolumnChart from "pages/charts/MachineStops/ShowMachStopcolumnChart.jsx";
import ShowMachStopPieChart from "pages/charts/MachineStops/ShowMachStopPieChart.jsx";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";

function MachineStopsControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] = useState(true);
  const [error, setError] = useState(false);
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedDataType, setSelectedDataType] = useState("Count");
  const [machineRenderedAsRadio, setMachineRenderedAsRadio] = useState(false);
  const [resultCountDataRows, setResultCountDataRows] = useState();
  const [resultDurnDataRows, setResultDurnDataRows] = useState();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);

        const filteredMachines = props.machines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        setMachines(filteredMachines);
        setMachineId(
          filteredMachines.length > 0 ? filteredMachines[0].id : null
        );

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);

        setMachineRenderedAsRadio(filteredMachines.length <= 2);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.machines]);

  /*useEffect(() => {
    // Trigger chart loading when data type, machine, start date, or end date changes
    if (selectedDataType && machineId && startDate && endDate) {
      handleLoadChart();
    }
  }, [selectedDataType, machineId, startDate, endDate]);*/
  useEffect(() => {
    if (isMounted) {
        handleLoadChart();
    } else {
        setIsMounted(true);
    }
}, [selectedDataType]);
  

  const handleLoadChart = async () => {
    let selectedMachineRec = machines.find((dt) => dt.id === machineId);

    if (!selectedMachineRec) {
      setError(true);
      return;
    }

    const p_param = {
      p_data_type: selectedDataType,
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };

    setIsLoading(true);
    setChartForm({
      ...chartForm,
      machineDesc: selectedMachineRec?.description || "Winding Machine #6",
      data: p_param,
    });
    try {
      const result = await loadApi(p_param, "/api/chart/getMachStopChrtData");
      if (result) {
        let successFlag = result.data[0];
        if (successFlag === "Y") {
          if (result.data[1].length > 0) {
            setResultCountDataRows(result.data[1]);
            setResultDurnDataRows(result.data[2]);
            setWasDataFetchingSuccessful(false);
            setError(false);
          } else {
            setError(true);
            setWasDataFetchingSuccessful(true);
          }
        } else {
          setError(true);
        }
      } else {
        setWasDataFetchingSuccessful(true);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDatatype = (e) => {
    setSelectedDataType(e.target.value);
  };

  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);

    const filteredMachines = props.machines.filter(
      (dt) => dt.process_id == selectedProcessId
    );
    setMachines(filteredMachines);
    setMachineRenderedAsRadio(filteredMachines.length <= 2);
    setMachineId(filteredMachines.length > 0 ? filteredMachines[0].id : null);
  };

  const handleMachineChange = (e) => {
    setMachineId(parseInt(e.target.value));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getDataTypeControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label"> Data Type </label>
        <div className="form-check">
          <input
            inputId={`${props.chartId}RblDataType0`}
            className="form-check-input"
            type="radio"
            value="Count"
            checked={selectedDataType === "Count"}
            name={`${props.chartId}RblDataType`}
            onChange={handleDatatype}
          />
          <label
            className="form-check-label"
            htmlFor={`${props.chartId}RblDataType0`}
          >
            Count
          </label>
        </div>
        <div className="form-check">
          <input
            inputId={`${props.chartId}RblDataType1`}
            className="form-check-input"
            type="radio"
            value="Duration"
            checked={selectedDataType === "Duration"}
            name={`${props.chartId}RblDataType`}
            onChange={handleDatatype}
          />
          <label
            className="form-check-label"
            htmlFor={`${props.chartId}RblDataType1`}
          >
            Duration
          </label>
        </div>
      </div>
    </React.Fragment>
  );

  const getProcessControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className={props.processes.length === 1 ? "form-label mb-3" : "form-label"}>
          Process
        </label>
        <div className="form-check">
          {props.processes.map((input) => (
            <div key={input.id}>
              <input
                inputId={`${props.chartId}ProcessRadBtn${input.id}`}
                className="form-check-input"
                type="radio"
                name={`${props.chartId}ProcessRadBtn`}
                value={input.id}
                onChange={handleProcessChange}
                checked={processId == input.id}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getMachineControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label" htmlFor="controlItemSelect">
          Machine
        </label>
        {machineRenderedAsRadio ? (
          <div className="form-check">
            {machines.map((input) => (
              <div key={input.id}>
                <input
                  inputId={`${props.chartId}MachineRadBtn${input.id}`}
                  className="form-check-input"
                  type="radio"
                  name={`${props.chartId}MachineRadBtn`}
                  value={input.id}
                  onChange={handleMachineChange}
                  checked={machineId === input.id}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${props.chartId}MachineRadBtn${input.id}`}
                >
                  {input.description}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <select
            className="form-select"
            id={`${props.chartId}MachineDrpDnList`}
            name={`${props.chartId}MachineDrpDnList`}
            onChange={handleMachineChange}
            value={machineId || ""}
          >
            {machines.map((option) => (
              <option key={option.id} value={option.id}>
                {option.description}
              </option>
            ))}
          </select>
        )}
      </div>
    </React.Fragment>
  );

  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "StartDateTime"}
          name={props.chartId + "StartDateTime"}
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "EndDateTime"}
          name={props.chartId + "EndDateTime"}
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );

  const getLoadButtonControl = () => (
    <React.Fragment>
      <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
        <button
          type="button"
          className="btn btn-primary load-button"
          id={props.chartId + "chartAreaId"}
          onClick={() => handleLoadChart()}
        >
          <i
            className={
              isLoading ? "pi pi-spin pi-spinner" : "pi pi-chart-bar"
            }
          ></i>
          <span>{isLoading ? "Loading" : "Load"}</span>
        </button>
      </div>
    </React.Fragment>
  );

  return (
    <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getDataTypeControl()}
          {getProcessControl()}
          {getMachineControl()}
          {getStartEndDateControls()}
          {getLoadButtonControl()}
        </div>
        {error && (
          <div className="row mt-8 justify-content-md-center">
            <div className="col-12">
              <div className="card border-secondary">
                <div className="card-header bg-dark text-white">
                  Machine Stops charts are Unavailable
                </div>
                <div className="card-body card-body-error rounded-bottom">
                  <ul>
                    <li>No data Found</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {!wasDataFetchingSuccessful && (
          <div className="row my-3">
            <div className="col-6 col-lg mb-3 mb-lg-0">
              <ShowMachStopcolumnChart
                resultCountDataRows={resultCountDataRows}
                resultDurnDataRows={resultDurnDataRows}
                formData={chartForm}
                id={props.chartId + "Column Chart"}
              />
            </div>
            <div className="col-6 col-lg">
              <ShowMachStopPieChart
                resultCountDataRows={resultCountDataRows}
                resultDurnDataRows={resultDurnDataRows}
                formData={chartForm}
                id={props.chartId + "Pie Chart"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MachineStopsControlListSelector;
