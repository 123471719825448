import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "../../services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const MachinesPG = () => {
  let emptyRow = {
    machine_id: null,
    machine_name: "",
    machine_description: "",
    thereotical_capacity: 0,
    created_by: null,
    creation_date: "",
    last_updated_by: null,
    last_update_date: "",
    last_update_login: null,
    prod_enabled_flag: "Y",
    start_date: "2024-06-28T17:12:46.000Z",
    end_date: "",
    production_speed: 0,
    target_efficiency: 0,
    wait_time_sort_order: 0,
    prod_count_flag: "N",
    machine_code: "",
  };

  /**** 31-May-2024 Velmani P. Following changes are made to template
   * 	a. Use a generic name "row" instead of "product".  Following are the name changed from template
   * 	   a1. products ==> rows
   * 		[products, setProducts] ==> [products, setRows]
   * 		[deleteProductsDialog, setDeleteProductsDialog] ==> [deleteRowsDialog, setDeleteRowsDialog]
   * 		[selectedProducts, setSelectedProducts] ==> [selectedRows, setSelectedRows]
   * 	   a2. product  ==> row
   *		[productDialog, setProductDialog] ==> [productDialog, setRowDialog]
   *		[deleteProductDialog, setDeleteProductDialog] ==> [deleteRowDialog, setDeleteRowDialog]
   *		[product, setProduct] ==> [product, setRow]
   * ******/
  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [machines, setMachines] = useState({}); 
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/machines/getAllMachines");
        console.log("Fetched machines:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching machines:", error);
      }
    };

    fetchRows();
  }, []);


  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const hideDeleteRowDialog = () => {
    setDeleteRowDialog(false);
  };

  const formatDateColumn = (dateValue) => {
    return dateValue ? moment(dateValue).format("YYYY-MM-DD") : null;
  };

  const startDateBodyTemplate = (rowData) => {
    return formatDateColumn(rowData.start_date);
  };

  const endDateBodyTemplate = (rowData) => {
    return formatDateColumn(rowData.end_date);
  };

  /***
        const formatDateFields = (row, isNew) => {
            const formatDate = (date) => date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
    
            return {
                ...row,
                creation_date: isNew ? formatDate(new Date()) : formatDate(row.creation_date),
                last_update_date: formatDate(new Date()),
                start_date: formatDate(row.start_date),
                end_date: formatDate(row.end_date)
            };
        };
         */

  const saveRow = async () => {
    setSubmitted(true);

    if (row.machine_name.trim()) {
      //      const isNew = !row.machine_id;
      //     const formattedRow = formatDateFields(row, isNew);

      let _rows = [...rows];
      let _row = { ...row };

      try {
        if (row.machine_id) {
          /*** Update Row */
          const response = await saveApi(row, "/api/setup/machines/updRow");
          //await axios.put(`http://localhost:5000/api/machines/${row.machine_id}`, formattedRow);
          //             const updatedRows = rows.map((p) => (p.machine_id === row.machine_id ? formattedRow : p));
          //             setRows(updatedRows);
          console.log("updating");
          console.log(response.data);
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            const index = findIndexById(row.machine_id);
            _rows[index] = _row;
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Machine Updated",
              life: 3000,
              width: "600px",
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Machine Not Updated",
              life: 3000,
              width: "600px",
            });
          }
        } else {
          /*** Insert Row */
          const response = await saveApi(row, "/api/setup/machines/insRow");
          //const response = await axios.post('http://localhost:5000/api/machines', formattedRow);
          //       formattedRow.machine_id = response.data.id;
          //     setRows([...rows, formattedRow]);
          console.log("saving");
          console.log(response.data);
          let successFlag =
            response.data[0]; /** This contains value of Y or N */
          if (successFlag === "Y") {
            _row.machine_id =
              response.data[1]; /** It contains newly created Id */
            _rows.push(_row);
            toast.current.show({
              severity: "success",
              summary: (
                <div style={{ marginright: "200px", width: "600px" }}>
                  err_msg
                </div>
              ),
              detail: <div style={{ width: "600px" }}>err_msg</div>,
              life: 3000,
              width: "600px",
            });
          } else {
            let err_msg = response.data[2];
            toast.current.show({
              severity: "error",
              summary: <div style={{ width: "600px" }}>{err_msg}</div>,
              detail: <div style={{ width: "600px" }}>{err_msg}</div>,
              life: 3000,
              width: "600px",
            });
          }
        }

        setRows(_rows);
        setRowDialog(false);
        setRow(emptyRow);
      } catch (error) {
        console.error("Error saving machine:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Machine Save Failed",
          life: 3000,
          width: "600px",
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        life: 3000,
        width: "600px",
      });
    }
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const confirmDeleteRow = (row) => {
    setRow(row);
    setDeleteRowDialog(true);
  };

  const deleteRow = async () => {
    try {
      const response = await saveApi(row, "api/setup/machines/delRow");
      let successFlag = response.data[0];
      if (successFlag === "Y") {
        let _rows = rows.filter((val) => val.machine_id !== row.machine_id);
        setRows(_rows);
        setDeleteRowDialog(false);
        setRow(emptyRow);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Machine Deleted",
          life: 3000,
          width: "600px",
        });
      } else {
        let err_msg = response.data[1];
        toast.current.show({
          severity: "error",
          summary: err_msg,
          detail: "Machine Not Deleted",
          life: 3000,
          width: "600px",
        });
      }
    } catch (error) {
      console.error("Error deleting machine:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Machine Deletion Failed",
        life: 3000,
        width: "600px",
      });
    }
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].machine_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Setup Machines
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> Setup Machines </span><br/><br/>
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px; 
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div>
         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }
  

  const deleteRowDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteRowDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteRow}
      />
    </React.Fragment>
  );

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _row = { ...row };
    _row[`${name}`] = val;

    setRow(_row);
  };

  /***
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const headerDontUse = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Machines</h5>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span> * /}
        </div>
    );
    */

    const header = (
      <div
        className="table-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "3rem",
          alignContent: "center",
        }}
      >
        <div
          style={{
            width: window.innerWidth / 3,
            display: "flex",
            justifyContent: "flex-start",
            marginRight: "110px",
          }}
        >
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      </div>
      <div>
      <Button
          // label="New"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editRow(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteRow(rowData)}
        />
      </React.Fragment>
    );
  };

  const fetchMachines = async (machine_id) => {
    const p_param = {
        p_machine_id: machine_id
    };
    console.log(p_param);
    try {
        const response = await loadApi(p_param, "/api/setup/machineAddresses/selRows"); // Replace with actual API call
        console.log("Fetched Adddress:", response.data);

        if (response.data.length > 0) {
            const successFlag = response.data[0];
            if (successFlag === "Y") {
                setMachines(prevMachines => ({ ...prevMachines, [machine_id]: response.data[1] }));
            } else {
                setMachines(prevMachines => ({ ...prevMachines, [machine_id]: [] }));
            }
        } else {
            setMachines(prevMachines => ({ ...prevMachines, [machine_id]: [] }));
        }
    } catch (error) {
        console.error("Error fetching machines:", error);
    } finally {
        setIsLoading(false);
    }
};


// Fetch books when a row is expanded
useEffect(() => {
  expandedRowId.forEach(machine_id => fetchMachines(machine_id));
}, [expandedRowId]);

const drillDownTemplate = (rowData) => {
  const isExpanded = expandedRowId.includes(rowData.machine_id);

  return (
      <Button
          icon={isExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'}
          className={`p-button-rounded p-button-sm ${isExpanded ? 'p-button-danger' : 'p-button-primary'}`}
          onClick={() => toggleRowExpansion(rowData.machine_id)}
          style={{ fontSize: '1rem' }} // Adjust size as needed
      />
  );
};

// Toggle row expansion to show/hide details
const toggleRowExpansion = (rowId) => {
  setExpandedRowId(prevExpandedRowId => 
      prevExpandedRowId.includes(rowId) 
          ? prevExpandedRowId.filter(id => id !== rowId) 
          : [...prevExpandedRowId, rowId]
  );
};

// Template for displaying the expanded row as a DataTable
const rowExpansionTemplate = (data) => {
    const machineData = machines[data.machine_id] || [];

    return (
        <div className="p-3">
          <DataTable value={machineData} responsiveLayout="scroll">
                    <Column field="machine_name" header="Machine Name" sortable></Column>
          <Column
            field="machine_address_name"
            header="Machine Address Name"
            sortable
          ></Column>
          <Column
            field="machine_description"
            header="Machine Description"
            sortable
          ></Column>
          <Column
            field="machine_address_code"
            header="Machine Address Code"
            sortable
          ></Column>
          <Column
            field="is_production_count"
            header="Is Production Count"
            sortable
          ></Column>
          <Column
            field="is_duration_second"
            header="Is Duration Second"
            sortable
          ></Column>
          <Column
            field="is_duration_minute"
            header="Is Duration Minute"
            sortable
          ></Column>
                </DataTable>
        </div>
    );
};



  return (
    <div>
      <Toast ref={toast} />
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">Setup Machines</div> */}
        <DataTable
          ref={dt}
          value={rows}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="machine_id"
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} machines"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No machines found"
          stripedRows
          showGridlines
          expandedRows={expandedRowId.map(machine_id => rows.find(rows => rows.machine_id === machine_id))}
          onRowToggle={(e) => {
              const rowId = e.data ? e.data.machine_id : null;
              setExpandedRowId(prev => 
              prev.includes(rowId) 
              ? prev.filter(machine_id => machine_id !== rowId) 
              : [...prev, rowId]
              );
            }}
          rowExpansionTemplate={rowExpansionTemplate}
          responsiveLayout="scroll"
          rowExpansion
        >
           <Column body={drillDownTemplate} style={{ width: '3rem', textAlign: 'center' }} />
          <Column
            field="machine_id"
            header="id"
            sortable="false"
            hidden="true"
          ></Column>
          <Column field="machine_name" header="Machine Name" sortable></Column>
          <Column field="machine_code" header="Machine Code" sortable></Column>
          <Column
            field="machine_description"
            header="Description"
            sortable
          ></Column>
          <Column
            field="production_speed"
            header="Production Speed"
            sortable
          ></Column>
          <Column
            field="target_efficiency"
            header="Target Efficiency"
            sortable
          ></Column>
          <Column
            field="thereotical_capacity"
            header="Theoretical Capacity"
            sortable
          ></Column>

          <Column
            field="prod_enabled_flag"
            header="Prod Enabled"
            sortable
          ></Column>
          <Column
            field="start_date"
            header="Start Date Active"
            body={startDateBodyTemplate}
            sortable
          ></Column>
          <Column
            field="end_date"
            header="End Date Active"
            body={endDateBodyTemplate}
            sortable
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
        )}

      <Dialog
        visible={rowDialog}
        style={{ width: "450px" }}
        header="Machine Details"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <div className="p-field">
          <label htmlFor="machine_name">Name</label>
          <InputText
            id="machine_name"
            value={row.machine_name}
            onChange={(e) => onInputChange(e, "machine_name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !row.machine_name,
            })}
          />
          {submitted && !row.machine_name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="machine_description">Description</label>
          <InputTextarea
            id="machine_description"
            value={row.machine_description}
            onChange={(e) => onInputChange(e, "machine_description")}
            required
            rows={3}
            cols={20}
          />
        </div>
        <div className="p-field">
          <label htmlFor="thereotical_capacity">Theoretical Capacity</label>
          <InputNumber
            id="thereotical_capacity"
            value={row.thereotical_capacity}
            onValueChange={(e) => onInputChange(e, "thereotical_capacity")}
            mode="decimal"
            minFractionDigits={2}
          />
        </div>
        <div className="p-field">
          <label htmlFor="production_speed">Production Speed</label>
          <InputNumber
            id="production_speed"
            value={row.production_speed}
            onValueChange={(e) => onInputChange(e, "production_speed")}
            mode="decimal"
            minFractionDigits={2}
          />
        </div>
        <div className="p-field">
          <label htmlFor="target_efficiency">Target Efficiency</label>
          <InputNumber
            id="target_efficiency"
            value={row.target_efficiency}
            onValueChange={(e) => onInputChange(e, "target_efficiency")}
            mode="decimal"
            minFractionDigits={2}
          />
        </div>
        <div className="p-field-checkbox">
          <Checkbox
            inputId="prod_enabled_flag"
            value={row.prod_enabled_flag}
            onChange={(e) => onInputChange(e, "prod_enabled_flag")}
            checked={row.prod_enabled_flag === "Y"}
          />
          <label htmlFor="prod_enabled_flag">Prod Enabled</label>
        </div>
        <div className="p-field">
          <label htmlFor="machine_code">Machine Code</label>
          <InputText
            id="machine_code"
            value={row.machine_code}
            onChange={(e) => onInputChange(e, "machine_code")}
          />
        </div>
        <div className="p-field">
          <label htmlFor="start_date">start date</label>
          <InputText
            id="start_date"
            value={row.start_date}
            onChange={(e) => onInputChange(e, "start_date")}
          />
        </div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveRow}
        />
      </Dialog>

      <Dialog
        visible={deleteRowDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteRowDialogFooter}
        onHide={hideDeleteRowDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {row && (
            <span>
              Are you sure you want to delete <b>{row.machine_name}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
    </div>
  );
};

export default MachinesPG;
