import React from 'react';
import { useEffect, useState } from 'react';
import { pecaDbName } from "services/envhttp";

function PecaTop() {
  const [currentTime, setCurrentTime] = useState('');

  const updateTime = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' }); 

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    const formattedTime = (
      <>
        {`${day}-${month}-${year} ${dayOfWeek}, ${hours}:${minutes}:${seconds}`}
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontWeight: 'normal' }}>Day of Year: {dayOfYear}</span>
      </>
    );
    
    setCurrentTime(formattedTime);
  };

  useEffect(() => {
    updateTime();
    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <div>
      <header className="d-print-none">
        <section id="panaHeader">
          <a id="panaHeaderPIW" href="https://iweb.is.jp.panasonic.com/gp/piw/en/" title="PIW Global Portal">
            PIW Global Portal
          </a>
          <div id="panaHeaderTxt">
            Panasonic Energy Corporation of America 
            <span style={{ marginLeft: '10em' }}>
              <strong style={{ fontSize: '1.2em' }}> 
                {pecaDbName}
              </strong>
            </span>
            <br />
            {currentTime}
            <div id="panaHeaderTxtCal">
              <span id="panaHeaderTxtCalDateTime">&nbsp;</span>
              <span id="panaHeaderTxtCalDayYear">&nbsp;</span>
            </div>
          </div>
          <div id="panaHeaderRight">
            <a id="panaHeaderRightPIW" href="https://iweb.is.jp.panasonic.com/scube/energy/en/" target="_blank" title="PIW Global Portal">
              PIW Global Portal
            </a>
            <span id="panaHeaderRightLogo" title="Panasonic Intranet">
              Panasonic Intranet
            </span>
          </div>
        </section>
      </header>
    </div>
  )
}

export default PecaTop