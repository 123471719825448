import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import moment from "moment";

function ShowMachStopcolumnChart(props) {
  // Define data
  let resultCountDataRows = props.resultCountDataRows;
  let resultDurnDataRows = props.resultDurnDataRows;
  let formData = props.formData;
  let chartId = props.id;

  let selectedDataType = formData.data.p_data_type;
  console.log("ShowMachStopChart resultCountDataRows:", resultCountDataRows);
  console.log("ShowMachStopChart resultDurnDataRows:", resultDurnDataRows);
  console.log("ShowMachStopChart formData:", formData);
  console.log("ShowMachStopChart chartId:", chartId);
  console.log("ShowMachStopChart selectedDataType:", selectedDataType);

  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
          dataSets: {
            byCounts: structuredClone(this.dataSet.countRecords.records),
            byDurations: structuredClone(this.dataSet.durationRecords.records)
          },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat("en-US", {
      year: "2-digit" /*** To check: in chart-form-controls-mixin.js, it is  "2-digit" ***/,
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    startingDateTime: new Date(formData.data.p_start_datetime),
    endingDateTime: new Date(formData.data.p_end_datetime),
  };

  console.log(dates.startingDateTime);

  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /***
   * Step 1 Calculate values
   **************/

  let capitalizedSelectedDataType =
    selectedDataType[0].toUpperCase() +
    selectedDataType.substr(1).toLowerCase();
  console.log("capitalizedSelectedDataType: ", capitalizedSelectedDataType);
  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   *
   */

  const startingDateTimeFmt = dates.dateTimeFormat
    .format(dates.startingDateTime)
    .replace(/,/g, "");
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat
    .format(dates.endingDateTime)
    .replace(/,/g, "");
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2.a Calculate chartTitles
   *
   */
  const chartTitles = {
    subTitles: {
      loadedOnDateTime: `@${dates.dateTimeFormat
        .format(new Date())
        .replace(/,/g, "")}`,
      timeSpan: `${startingDateTimeFmt} - ${endingDateTimeFmt}`,
    },
    title: `${formData.machineDesc} Stop Times By ${capitalizedSelectedDataType}`,
  };

  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix =
    formData.machineDesc +
    "-efficiency-by-time-" +
    startingDateTimeFmt +
    "|" +
    endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);

  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   *
   */

  function setExpMenus(root) {
    // set Export Menu
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top",
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      xlsxOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      pdfOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
    });

    expPlugin.get("menu").get("items").shift();
    expPlugin.get("menu").get("items").pop();
    expPlugin.get("menu").get("items").pop();
  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      paddingTop:50,
      text: chartTitles.title,
      x: am5.p50,
    });

    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 2,
      }),
      paddingBottom: 0,
      paddingLeft: 15,
      paddingRight: -10,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0,
    });

    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50,
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100,
      y: -50
    });
    
    subTitle.children.push(fetchedOnLabel);
    subTitle.children.push(timespanLabel);

    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
  }

  useLayoutEffect(() => {
    //createChart() logics in vue are implemented here.
    let root = am5.Root.new(chartId);
    root.utc = false;

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ]);

    // Chart Creation
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingRight: 25,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingTop: 0,
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
      })
    );

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04,
    });

    chart.zoomOutButton.set("forceHidden", true);

    // set export menu options for the chart.
    setExpMenus(root);

    setTitles(root, chart);

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        categoryYField: "description",
        numberFormat: "#,##0",
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      minPosition: 0,
      maxPosition: 0.9,
    });

    let xAxisTitleLabel = am5.Label.new(root, {
      fontWeight: "500",
      text: `${capitalizedSelectedDataType} of Machine Stops`,
      textAlign: "center",
      x: am5.p50,
    });
    xAxis.children.push(xAxisTitleLabel);

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "description",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          minGridDistance: 10,
        }),
        // tooltip needs to be made for tooltipLocation to work, but we don't want to show it so it's hidden
        // tooltipLocation locks the cursor a 0 - 1 location of each axis element (0.5 is middle)
        tooltip: am5.Tooltip.new(root, {
          forceHidden: true,
        }),
        tooltipLocation: 0.5,
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      multiLocation: 0.5,
      rotation: -30,
    });
    yAxis.children.unshift(
      am5.Label.new(root, {
        fontWeight: "500",
        rotation: -90,
        text: "Machine Stop Time Reason",
        textAlign: "center",
        y: am5.p50,
      })
    );

    //series 1 On Value
    //
    let seriesValue = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        categoryYField: "description",
        legendLabelText: "[{stroke}]{name}[/]",
        name: "Value",
        sequencedInterpolation: true,
        valueXField: "value",
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    seriesValue.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 3,
    });

    seriesValue.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(seriesValue.columns.indexOf(target));
    });

    seriesValue.columns.template.adapters.add("stroke", (stroke, target) => {
      return chart.get("colors").getIndex(seriesValue.columns.indexOf(target));
    });

    // DataProcessor steps may not be needed in React JS; - Velmani
    // let dataProcessor = am5.DataProcessor.new(root, {
    //   numericFields: ["value", "sortValue"],
    // });
    // dataProcessor.processMany(resultCountDataRows);
    // dataProcessor.processMany(resultDurnDataRows);

    let chartCursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        yAxis: yAxis,
      })
    );
    chartCursor.lineX.set("visible", false);

    if (selectedDataType === "Count") {
      //   xAxis.data.setAll(resultCountDataRows);
      yAxis.data.setAll(resultCountDataRows);
      seriesValue.data.setAll(resultCountDataRows);
      console.log("in count: ", resultCountDataRows);
    } else {
      //   xAxis.data.setAll(resultDurnDataRows);
      yAxis.data.setAll(resultDurnDataRows);
      seriesValue.data.setAll(resultDurnDataRows);
      console.log("in duration: ", resultDurnDataRows);
    }

    seriesValue.appear(100);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [formData]);

  return (
    // className="bg-white shadow rounded chart-am5"
    <div id={props.id} className="bg-white shadow rounded chart-am5"></div>
  );
}

export default ShowMachStopcolumnChart;
