import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import moment from "moment";

function ShowMachStopPieChart(props) {
  // Define data
  let resultCountDataRows = props.resultCountDataRows;
  let resultDurnDataRows = props.resultDurnDataRows;
  let formData = props.formData;
  let chartId = props.id;

  let selectedDataType = formData.data.p_data_type;
  console.log("ShowMachStopPieChart resultCountDataRows:", resultCountDataRows);
  console.log("ShowMachStopPieChart resultDurnDataRows:", resultDurnDataRows);
  console.log("ShowMachStopPieChart formData:", formData);
  console.log("ShowMachStopPieChart chartId:", chartId);
  console.log("ShowMachStopPieChart selectedDataType:", selectedDataType);

  /**** Initialize variables chartingComponents as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  /*** The React JS program is easier to define needed variable seperately.  So, calendarDateTime is not need and is commented.  Velmani ***
  console.log("EffOpTimeChart dataSet:", dataSet);
  let chartingComponents = {
    cursor: {},
          dataSets: {
            byCounts: structuredClone(this.dataSet.countRecords.records),
            byDurations: structuredClone(this.dataSet.durationRecords.records)
          },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    //title: {},
    //xAxis: {},
    //xAxisTitleLabel: {},
    yAxes: {}
  }
  * **************/

  /**** Initialize variable dates as found in data() of Vue source code machine-efficiency-by-units-produced-chart.vue */
  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat("en-US", {
      year: "2-digit" /*** To check: in chart-form-controls-mixin.js, it is  "2-digit" ***/,
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }),
    startingDateTime: new Date(formData.data.p_start_datetime),
    endingDateTime: new Date(formData.data.p_end_datetime),
  };

  console.log(dates.startingDateTime);

  /**** Initialize chartTitles as found in computed: of Vue source code machine-efficiency-by-units-produced-chart.vue */

  /***
   * Step 1 Calculate values
   **************/

  let capitalizedSelectedDataType =
    selectedDataType[0].toUpperCase() +
    selectedDataType.substr(1).toLowerCase();
  console.log("capitalizedSelectedDataType: ", capitalizedSelectedDataType);
  /****
   * Step 2 Calculate Timespan. ?? To do: need to find how it is used
   *
   */

  const startingDateTimeFmt = dates.dateTimeFormat
    .format(dates.startingDateTime)
    .replace(/,/g, "");
  console.log("startingDateTimeFmt", startingDateTimeFmt);
  const endingDateTimeFmt = dates.dateTimeFormat
    .format(dates.endingDateTime)
    .replace(/,/g, "");
  console.log("endingDateTimeFmt", endingDateTimeFmt);

  /****
   * Step 2.a Calculate chartTitles
   *
   */
  const chartTitles = {
    subTitles: {
      loadedOnDateTime: `@${dates.dateTimeFormat
        .format(new Date())
        .replace(/,/g, "")}`,
      timeSpan: `${startingDateTimeFmt} - ${endingDateTimeFmt}`,
    },
    title: `${formData.machineDesc} Stop Times By ${capitalizedSelectedDataType}`,
  };

  /****
   * Step 3 Calculate exportFilePrefix
   ***/
  const exportFilePrefix =
    formData.machineDesc +
    "-efficiency-by-time-" +
    startingDateTimeFmt +
    "|" +
    endingDateTimeFmt;
  console.log("exportFilePrefix", exportFilePrefix);

  /****
   * Step 4. Some logics found in watch section of vue code.
   * Note: this may be included in one of these
   * 	a. Above variables asssignments
   * 	b. Inside useLayoutEffect
   *
   */

  function setExpMenus(root) {
    // set Export Menu
    console.log("set menu started");
    let expMenu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top",
    });

    expMenu.getPrivate("menuElement").querySelector("svg").remove();
    expMenu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    let expPlugin = am5plugins_exporting.Exporting.new(root, {
      filePrefix: exportFilePrefix,
      menu: expMenu,
      // disable certain formats
      xlsxOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      pdfOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
    });

    expPlugin.get("menu").get("items").shift();
    expPlugin.get("menu").get("items").pop();
    expPlugin.get("menu").get("items").pop();

    console.log("setup Menu excuted");
  }

  function setTitles(root, chart) {
    // Set Title of the chart
    let mainTitle = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      paddingTop:50,
      text: chartTitles.title,
      x: am5.p50,
    });

    let subTitle = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 2,
      }),
      paddingBottom: 0,
      paddingLeft: 15,
      paddingRight: -10,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0,
    });

    let timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50,
    });
    let fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100,
      y: -50
    });

    subTitle.children.push(fetchedOnLabel);
    subTitle.children.push(timespanLabel);

    chart.children.unshift(subTitle);
    chart.children.unshift(mainTitle);
  }

  useLayoutEffect(() => {
    //createChart() logics in vue are implemented here.
    console.log("use layout started");
    let root = am5.Root.new(chartId);
    root.utc = false;
    console.log("root executed");
    root.setThemes([am5themes_Animated.new(root)]);

    // Chart Creation
    console.log("chart started");
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(100),
      })
    );
    console.log("chart executed");

    // chart.zoomOutButton.set("forceHidden", true);
    console.log("calling set exp menu");
    // set export menu options for the chart.
    setExpMenus(root);
    console.log("calling set title");
    setTitles(root, chart);
    console.log("Completed Calling set titles");
    //series 1 On Value
    //
    console.log("calling series value");
    let seriesValue = chart.series.push(
      am5percent.PieSeries.new(root, {
        alignLabels: false,
        categoryField: "description",
        legendLabelText:
          "[{fill}]{category}: [bold, underline, fontSize: 18px]{valuePercentTotal.formatNumber('0.00')}%[/] [{fill}, fontSize: 12px]({value})[/][/]",
        valueField: "value",
      })
    );

    seriesValue.labels.template.setAll({
      centerX: am5.percent(100),
      fill: am5.color(0xffffff),
      fontSize: 14,
      radius: 0,
      text: "{category}",
      textType: "radial",
    });

    seriesValue.slices.template.setAll({
      fillOpacity: 0.6,
      strokeWidth: 1,
      tooltipText:
        "[#fff]{category}: [bold, underline, fontSize: 18px]{valuePercentTotal.formatNumber('0.00')}%[/] [#fff, fontSize: 12px]({value})[/][/]",
    });

    seriesValue.ticks.template.set("visible", false);

    // DataProcessor steps may not be needed in React JS; - Velmani
    // let dataProcessor = am5.DataProcessor.new(root, {
    //   numericFields: ["value", "sortValue"],
    // });
    // dataProcessor.processMany(resultCountDataRows);
    // dataProcessor.processMany(resultDurnDataRows);
    if (selectedDataType === "Count") {
    seriesValue.data.setAll(resultCountDataRows);
    } else {
    seriesValue.data.setAll(resultDurnDataRows);
    }

    seriesValue.legend = chart.children.push(
      am5.Legend.new(root, {
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color("#343a40"),
          fillOpacity: 0.04,
          stroke: am5.color("#343a40"),
        }),
        centerX: am5.percent(50),
        marginBottom: 15,
        marginTop: 20,
        x: am5.percent(50),
      })
    );

    seriesValue.legend.markerRectangles.template.setAll({
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
    });
    seriesValue.legend.valueLabels.template.set("forceHidden", true);
    seriesValue.legend.labels.template.set("text", "{category}: {value} ({valuePercentTotal.formatNumber('#.0')}%)");

    var currentlySelected = [];
    seriesValue.legend.itemContainers.template.events.on("click", function(ev) {
    var targetDataItem = ev.target.dataItem.dataContext;
    var index = currentlySelected.findIndex(item => item._settings.value === targetDataItem._settings.value);
    if (index !== -1) {
        currentlySelected.splice(index, 1);
    } else {
        currentlySelected.push(targetDataItem);
    }
    var series = targetDataItem.component;
    am5.array.each(series.dataItems, function(seriesDataItem) {
        if (currentlySelected.length === 0) {
            seriesDataItem.show();
        } else if (currentlySelected.some(item => item._settings.value === seriesDataItem._settings.value)) {
            seriesDataItem.hide();
        } else {
            seriesDataItem.show();
        }
    });
});

    seriesValue.legend.data.setAll(seriesValue.dataItems);

    if (selectedDataType === "Count") {
      //   xAxis.data.setAll(resultCountDataRows);

      seriesValue.data.setAll(resultCountDataRows);
      console.log("in count: ", resultCountDataRows);
    } else {
      //   xAxis.data.setAll(resultDurnDataRows);

      seriesValue.data.setAll(resultDurnDataRows);
      console.log("in duration: ", resultDurnDataRows);
    }

    seriesValue.appear(100);
    seriesValue.legend.appear(1000);
    console.log("use layout executed");

    return () => {
      root.dispose();
    };
    console.log("use layout executed");
  }, [formData]);

  return (
    <div id={props.id} className="bg-white shadow rounded chart-am5"></div>
  );
}

export default ShowMachStopPieChart;
