import React, { useEffect, useState } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowParetoMachStopsChart from "pages/charts/ParetoMachStops/ShowParetoMachStopsChart.jsx";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";

function ParetoMachStopsFormControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [processLineId, setProcessLineId] = useState();
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [filteredLines, setFilteredLines] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [targetEfficiency, setTargetEfficiency] = useState();
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =
    useState(true);
  const [error, setError] = useState(false);
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [machineRenderedAsRadio, setMachineRenderedAsRadio] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);
        console.log(firstRowProcessId);

        const filteredLines = props.lines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        console.log(filteredLines);
        console.log(props.lines);
        setLines(filteredLines);
        setProcessLineId(
          filteredLines.length > 0 ? filteredLines[0].process_id : null
        );
        setSelectedLine(filteredLines.length > 0 ? filteredLines[0].line_number : null);

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);

        setMachineRenderedAsRadio(filteredLines.length <= 2);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.lines]);

  const handleLoadChart = async () => {
    // console.log(lines);
    let selectedLinesRec = props.lines.find((dt) => dt.line_number == selectedLine);
    let selectedProcessRec = props.processes.find((dt) => dt.id == processId);
    console.log("selected processes", selectedProcessRec);
    console.log(processId);
    console.log(selectedLinesRec);
    if (!selectedLinesRec) {
      setError(true);
      return;
    }

    const p_param = {
      p_process_id: processId,
      p_process_description: selectedProcessRec.description,
      p_line_number: selectedLinesRec.line_number,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };
    console.log(p_param);

    setIsLoading(true);
    setChartForm({
      ...chartForm,
      p_param,
    });
    console.log(chartForm);
    await loadApi(p_param, "/api/chart/getProcLineParetoMchStps")
      .then(async (result) => {
        if (result) {
          let successFlag = result.data[0];
          if (successFlag === "Y") {
            if (result.data[1].length > 0) {
              setChartData(result.data[1]);
              console.log("result.data[1]", result.data[1]);

              setWasDataFetchingSuccessful(false);
              setError(false);
            } else {
              setError(true);
              setWasDataFetchingSuccessful(true);
            }
          } else {
            setError(true);
          }
          setIsLoading(false);
        } else {
          setWasDataFetchingSuccessful(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        setError(true);
      });
  };

  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);
    console.log("selectedProcessId", selectedProcessId);

    const filteredLines = props.lines.filter(
      (dt) => dt.process_id == selectedProcessId
    );
    console.log(filteredLines);
    setLines(filteredLines);
    console.log(lines);
    console.log(props.lines);
    setMachineRenderedAsRadio(filteredLines.length <= 2);
    setProcessLineId(
      filteredLines.length > 0 ? filteredLines[0].process_idid : null
    );
    setSelectedLine(filteredLines.length > 0 ? filteredLines[0].line_number : null);
  };

  const handleLineChange = (e) => {
    const selectedLines = e.target.value;
    setSelectedLine(selectedLines);
    setFilteredLines(selectedLines);
    console.log('Selected machine number:', e.target.value);
    
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getProcessControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label
          className={
            props.processes.length === 1 ? "form-label mb-3" : "form-label"
          }
        >
          Process
        </label>
        <div className="form-check" onChange={handleProcessChange}>
          {props.processes.map((input) => (
            <div key={input.id}>
              <input
                className="form-check-input"
                type="radio"
                id={`${props.chartId}ProcessRadBtn${input.id}`}
                name={`${props.chartId}ProcessRadBtn`}
                value={input.id}
                checked={processId == input.id}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getLineControl = () =>(
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label
          className={
            props.lines.length === 1 ? "form-label mb-3" : "form-label"
          }
        >
          Lines
        </label>

        <div className="form-check" onChange={handleLineChange}>
          {lines.map((input) => (
            <div key={input.line_number}>
              <input
                className="form-check-input"
                type="radio"
                id={`${props.chartId}LinesRadBtn${input.line_number}`}
                name={`${props.chartId}LinesRadBtn`}
                value={input.line_number}
                onChange={handleLineChange}
                checked={selectedLine == input.line_number}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}LinesRadBtn${input.line_number}`}
              >
                {input.line_number}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  console.log(selectedLine);


  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "StartDateTime"}
          name={props.chartId + "StartDateTime"}
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "EndDateTime"}
          name={props.chartId + "EndDateTime"}
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );

  const getLoadButtonControl = () => (
    <React.Fragment>
        <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
          <button
            type="button"
            className="btn btn-primary load-button"
            id={props.chartId + "chartAreaId"}
            onClick={handleLoadChart}
          >
            <i
              className={
                isLoading ? "pi pi-spin pi-spinner" : "pi pi-chart-bar"
              }
            ></i>
            <span>{isLoading ? "Loading" : "Load"}</span>
          </button>
        </div>
    </React.Fragment>
  );

  return (
    <div className="row">
      <div className="col">
      <div className="row justify-content-center">
        {getProcessControl()}
        {getLineControl()}
        {getStartEndDateControls()}
        {getLoadButtonControl()}
      </div>
      {error && (
        <div className="row mt-8 justify-content-md-center">
          <div className="col-12">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
              Pareto Machine Stop Chart are Unavailable
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul>
                  <li>No data Found</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {!wasDataFetchingSuccessful && (
        <div className="row mt-8 my-5 justify-content-md-center">
          <div className="col">
            <ShowParetoMachStopsChart
              chartData={chartData}
              chartForm={chartForm}
              id={props.chartId + "Pareto Chart1"}
            />
          </div>
        </div>
      )}
    </div>
    </div>

  );
}

export default ParetoMachStopsFormControlListSelector;
